import React from 'react'
import LayoutFAQ from "../../components/layout/layoutFAQ"
import FaqSidebar from "../../components/FAQs/faqSidebar"
import {Sections} from "../../components/FAQs/faqData"
const Grading = () => {
  return <>
    <FaqSidebar selected={Sections.grading}/>
  </>
}

Grading.Layout = LayoutFAQ

export default Grading
